/* APP HEADERS */

p {
  color: white;
}

.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: '#fff';
  background-image: linear-gradient(-90deg, rgb(0, 0, 0), rgb(34, 34, 34));
}

.App-link {
  color: #7c0e0e;
}

/* DEFAULTS */


h1 {
  padding-bottom: 40px;
  color: white;
}

main {
  background-color: #282c34;
}


/* MIDDLE */

.section {
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;
}

.dark {
  background-color: #16191f;
}

/* FOOTER */

footer {
  background-color: #282c34;
  color: white;
  padding: 40px;
}

.social-icons {
  color: white;
  font-size: 30px;
  margin: 20px;
}

.social-icons:hover {
  color: #7c0e0e;
}
